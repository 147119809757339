import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { actions as authActions } from "./../../shared-redux/Auth/slice";
import Header from "../../components/Header";
import {
  DatePicker,
  Input,
  Table,
} from "rsuite";
import moment from "moment-timezone";
import _ from "lodash";
import ConfirmationModel from "../../components/ConfirmationModel";

const { Column, Cell, HeaderCell } = Table;

export const MecList = () => {
  const tableRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [tableHeight, seTableHeight] = useState(window.innerHeight);
  const [disclosureDob,setDisclosureDob] = useState()
  const [disclosureMBI,setDisclouserMBI] = useState("")
  const [mecData,setMecData] = useState()
  const [agreedDisclosure,setAgreedDisclosure] = useState(false)
  const [showDisclosureModal,setShowDisclosureModal] = useState(false)
  const dataColumn = [
  { data: "First Name",type:'',output:'personalInfo.firstName',startDate:'',endDate:'' },
  { data: "Last Name",type:'',output:'personalInfo.lastName',startDate:'',endDate:'' },
  { data: "MBI",type:'',output:'personalInfo.medicareBeneficiaryIdentifier',startDate:'',endDate:''},
  { data: "Date of Birth",type:'',output:'personalInfo.birthDate',startDate:'',endDate:''},
  { data: "Date of Death",type:'',output:'personalInfo.deathDate',startDate:'',endDate:''},
  { data: "Gender",type:'',output:'personalInfo.gender',startDate:'',endDate:'' },
  {data:"",type:'blank'},
  {data:"Mailing:",type:'heading'},
  { data: "Street address",type:'',output:'mailingAddress.addressLines',startDate:'mailingAddress.addressStartDate',endDate:'mailingAddress.addressEndDate' },
  { data: "City",type:'',output:'mailingAddress.city',startDate:'',endDate:''},
  { data: "State",type:'',output:'mailingAddress.postalStateCode',startDate:'',endDate:'' },
  { data: "Zip",type:'',output:'mailingAddress.zipCode',startDate:'',endDate:'' },
  {data:"",type:'blank'},
  {data:"Residential:",type:'heading'},
  { data: "Street address",type:'',output:'residenceAddress.addressLines',startDate:'residenceAddress.addressStartDate',endDate:'residenceAddress.addressEndDate' },
  { data: "City",type:'',output:'residenceAddress.city',startDate:'',endDate:'' },
  { data: "State",type:'',output:'residenceAddress.postalStateCode',startDate:'',endDate:'' },
  { data: "Zip",type:'',output:'residenceAddress.zipCode',startDate:'',endDate:'' },
  {data:"",type:'blank'},
  { data: "Part A",type:'',output:'',startDate:'partA.startDate',endDate:'partA.stopDate' },
  { data: "Part B",type:'',output:'',startDate:'partB.startDate',endDate:'partB.stopDate' },
  { data: "Part D",type:'',output:'',startDate:'partD.startDate',endDate:'partD.stopDate' },
  {data:"",type:'blank'},
  { data: "LEP",type:'',output:'LEP.output',startDate:'LEP.startDate',endDate:'LEP.endDate',note:'LEP.notes' },
  { data: "Unlawfully present",type:'',output:'',startDate:'unLawFullPresent.startDate',endDate:'unLawFullPresent.stopDate' },
  { data: "Retiree drug coverage",type:'',output:'',startDate:'retireeDrugCoverage.startDate',endDate:'retireeDrugCoverage.stopDate' },
  { data: "Incarceration",type:'',output:'',startDate:'incarcerationPeriods.startDate',endDate:'incarcerationPeriods.stopDate' },
  { data: "CARA status",type:'',output:'',startDate:'caraPeriods.startDate',endDate:'caraPeriods.stopDate' },
  { data: "ESRD",type:'',output:'esrdCoverage.esrdIndicator',startDate:'',endDate:'' },
  {data:"",type:'blank'},
  {data:"",type:'blank'},
  { data: "Medicaid",type:'',output:'medicaid.status',startDate:'',endDate:'', note:'medicaid.output' },
  { data: "LIS",type:'',output:'lis.output',startDate:'lis.startDate',endDate:'lis.endDate' },
  { data: "Last use of Dual/LIS SEP",type:'',output:'latestSpecialEnrollmentPeriodUseDate',startDate:'',endDate:'' },
  {data:"",type:'blank'},
  {data:"",type:'blank'},
  {data:"Part C & D info:",type:'heading'},
  { data: "Future plan",type:'',output:'futurePlan.contractNo',startDate:'futurePlan.startDate',endDate:'futurePlan.endDate', note:'futurePlan.planName', hyperlink:'futurePlan.hyperlink' },
  { data: "Current plan",type:'',output:'currentPlan.contractNo',startDate:'currentPlan.startDate',endDate:'currentPlan.endDate', note:'currentPlan.planName', hyperlink:'currentPlan.hyperlink'  },
  { data: "Past plan 1",type:'',output:'pastPlans[0].contractNo',startDate:'pastPlans[0].startDate',endDate:'pastPlans[0].endDate', note:'pastPlans[0].planName', hyperlink:'pastPlans[0].hyperlink' },
  { data: "Past plan 2",type:'',output:'pastPlans[1].contractNo',startDate:'pastPlans[1].startDate',endDate:'pastPlans[1].endDate', note:'pastPlans[1].planName', hyperlink:'pastPlans[1].hyperlink' },
  { data: "Past plan 3",type:'',output:'pastPlans[2].contractNo',startDate:'pastPlans[2].startDate',endDate:'pastPlans[2].endDate', note:'pastPlans[2].planName', hyperlink:'pastPlans[2].hyperlink' },
  { data: "Past plan 4",type:'',output:'pastPlans[3].contractNo',startDate:'pastPlans[3].startDate',endDate:'pastPlans[3].endDate', note:'pastPlans[3].planName', hyperlink:'pastPlans[3].hyperlink' },
  { data: "Past plan 5",type:'',output:'pastPlans[4].contractNo',startDate:'pastPlans[4].startDate',endDate:'pastPlans[4].endDate', note:'pastPlans[4].planName', hyperlink:'pastPlans[4].hyperlink' },
  { data: "Past plan 6",type:'',output:'pastPlans[5].contractNo',startDate:'pastPlans[5].startDate',endDate:'pastPlans[5].endDate', note:'pastPlans[5].planName', hyperlink:'pastPlans[5].hyperlink' },
  { data: "Past plan 7",type:'',output:'pastPlans[6].contractNo',startDate:'pastPlans[6].startDate',endDate:'pastPlans[6].endDate', note:'pastPlans[6].planName', hyperlink:'pastPlans[6].hyperlink' },
  { data: "Past plan 8",type:'',output:'pastPlans[7].contractNo',startDate:'pastPlans[7].startDate',endDate:'pastPlans[7].endDate', note:'pastPlans[7].planName', hyperlink:'pastPlans[7].hyperlink' },
  { data: "Past plan 9",type:'',output:'pastPlans[8].contractNo',startDate:'pastPlans[8].startDate',endDate:'pastPlans[8].endDate', note:'pastPlans[8].planName', hyperlink:'pastPlans[8].hyperlink' },
  {data:"",type:'blank'},
  {data:'New MA sale will count as NTM / True Up?',type:'', output:"NTM",startDate:'',endDate:''}
]

const onAgreeDisclosure = async() => {
  setAgreedDisclosure(true)
  setLoading(true)
  dispatch({
      type: authActions.getMecData.type,
      payload:{
      dob:moment(disclosureDob).format('YYYY-MM-DD'),
      mbi:disclosureMBI,
      nowDate: moment().format('YYYY-MM-DD'),
        successCallback: (data) => {
          setMecData(data)
          setTimeout(() => {
            setLoading(false)
          },2000)
        }
      }
    })
}

  useEffect(() => {
    function updateSize() {
        seTableHeight(getTableHeight());
    }
    window.addEventListener('resize', updateSize);
  }, [])

  const getTableHeight = () => {
    try {
      const headerHeight = document.getElementById("headerID").offsetHeight;
      const windowHeight = window.innerHeight;
      return (
        windowHeight -
        (headerHeight)
      );
     
    } catch (e) {
      return window.innerHeight;
    }
  };


  useEffect(() => {
    seTableHeight(getTableHeight());
  }, [window.innerHeight]);

  const getValue = (key) => {
   try{
    if(!mecData)
      return;
    if(key.indexOf('.') != -1){
      const allKey = key.split('.');
      let data = mecData
      allKey.forEach(element => {
        if(element.indexOf('[') != -1){ // for array responses like plans
          data = data[`${element.split('[')[0]}`]
          data = data[parseInt(element.substring(element.indexOf('[')+1,element.indexOf('[')+2))]
        }else{
          data = data[`${element}`];
        }
        if(!data)
            return ''
     });
     if(data === true || data === false){
      return !data ? "No" : "Yes"
     }
      return data
    }else{
      
      let data= mecData[key]
      if(data === true || data === false){
        return !data ? "No" : "Yes"
       }
      return mecData[key];
    }
   }catch(error){
    console.log("error ==", error)
   }
}

  return (
    <div className="font-Poppins bg-gradient-to-r from-white from-40% via-green-300 via-60% to-white w-full h-screen overflow-hidden">
      <Header active={2} />
        <div className={`w-full`}>
          <div id="tableContainer" className="relative">
          {agreedDisclosure && !showDisclosureModal ? 
            <Table
              wordWrap="break-word"
              className="text-gray-400 "
              showHeader={true}
              ref={tableRef}
              affixHorizontalScrollbar
              shouldUpdateScroll={false}
              locale={{ emptyMessage: "" }}
              data={mecData?dataColumn:[]}
              rowHeight={60}
              height={tableHeight}
              headerHeight={50}
              loading={loading}
            >
      <Column flexGrow={1} resizable>
        <HeaderCell style={{ backgroundColor: 'rgb(86 164 91)', color: 'white',fontSize:'16px',fontWeight:'bold' }}>Data</HeaderCell>
        <Cell dataKey="data">
        {(rowData, index) => rowData.type ==="heading" ? <p className="underline font-semibold text-gray-500">{ rowData.data }</p> : rowData.data }
        </Cell>
      </Column>

      <Column flexGrow={1} resizable>
        <HeaderCell style={{ backgroundColor: 'rgb(86 164 91)', color: 'white',fontSize:'16px',fontWeight:'bold' }}>Output</HeaderCell>
        <Cell dataKey="output">
        {(rowData, index) => rowData.output && (rowData.type !== "blank" && rowData.type !== "heading") ? getValue(rowData.output): ''}
        </Cell>
      </Column>

      <Column flexGrow={1} resizable>
        <HeaderCell style={{ backgroundColor: 'rgb(86 164 91)', color: 'white',fontSize:'16px',fontWeight:'bold' }}>Start Date</HeaderCell>
        <Cell dataKey="startDate">
        {(rowData, index) =>rowData.startDate  && (rowData.type !== "blank" && rowData.type !== "heading") ? getValue(rowData.startDate) : ""}
        </Cell>
      </Column>

      <Column flexGrow={1} resizable>
        <HeaderCell style={{ backgroundColor: 'rgb(86 164 91)', color: 'white',fontSize:'16px',fontWeight:'bold' }}>End Date</HeaderCell>
        <Cell dataKey="endDate">
        {(rowData, index) =>rowData.endDate && (rowData.type !== "blank" && rowData.type !== "heading") ? getValue(rowData.endDate) : ''}
        </Cell>
      </Column>

      <Column flexGrow={1} resizable>
        <HeaderCell style={{ backgroundColor: 'rgb(86 164 91)', color: 'white',fontSize:'16px',fontWeight:'bold' }}>Note</HeaderCell>
        <Cell dataKey="note">
        {(rowData, index) => <div>{
          (rowData.hyperlink && rowData.note) ?
          <div className="cursor-pointer underline text-green-200" onClick={() => window.open(getValue(rowData.hyperlink), "_blank")}>{getValue(rowData?.note)}</div>
           : rowData?.note && (rowData.type !== "blank" && rowData.type !== "heading") ? getValue(rowData?.note) : ''
        }</div>
        }
        </Cell>
      </Column>
            </Table>
            :
            <div className="px-10">
              <div className="border rounded-lg px-10 py-5">
              <h1 className="text-base font-bold">Medicare Eligibility</h1>
                <div className="mt-5 mb-5 flex flex-col">
                <div className="w-52 mt-2">
                <div className="flex mb-2">Medicare ID:<span className="ml-1 -mt-1 text-sm text-red-400">*</span></div>
                <Input 
                className="w-full"
                placeholder="Enter the MBI"
                onChange={(e)=> setDisclouserMBI(e)}
                value={disclosureMBI}
                />
                </div>
                </div>
                <div className="mb-10">
                <div className="flex mb-2">Date of Birth:<span className="ml-1 -mt-1 text-sm text-red-400">*</span></div>
                <DatePicker
                  editable
                  cleanable
                  format={"MM-dd-yyyy"}
                  placeholder="Select Date"
                  onClean={(value) => {
                    setDisclosureDob(null)
                  }}
                  onChange={(value) => {
                    setDisclosureDob(value)
                  }}
                  value={disclosureDob}
                  defaultValue={
                    disclosureDob
                      ? new Date(
                          disclosureDob
                        )
                      : null
                  }
                />
                
                </div>
               
                <button disabled={!disclosureDob || !disclosureMBI} onClick={() => setShowDisclosureModal(true)} className={`${!disclosureDob || !disclosureMBI ? 'cursor-not-allowed' : 'cursor-pointer'} shadow-lg font-bold  px-5 py-2 text-xs bg-green-200 text-white rounded-md h-8`}>{`Submit`}</button>
              </div>
            </div>
            }
          </div>
      </div>
        <ConfirmationModel
          isVisible={showDisclosureModal}
          title="Disclosure"
          message ={<p>It is important that the information provided on an enrollment form is correct to the best of your knowledge. In an attemp to avoid any delay in your enrollment application being processed. We would like to verify your information with CMS prior to enrollment subission. Ask Applicant. Do we have your consent to complete this verification? Please note that during this validation, CMS may provide additional details regarding your eligibilty, which could assist your liecensed sales agent with choosing that best suits your need.</p>}
          onSave={()=>onAgreeDisclosure()}
          onClose={() => setShowDisclosureModal(false)}
          continueName="I Agree"
          cancelName="Cancel"
        />
    </div>
  );
};
