import React, {useState, useEffect} from 'react';
import {  Input, Table} from 'rsuite';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions as authActions } from "./../../shared-redux/Auth/slice";
import CopyToClipboardButton from '../CopyToClipboardButton';

const UserInfo = (props) => {
    const [limit,setLimit] = useState(0)
    const dispatch = useDispatch();
    const location = useLocation();

    const [userUpdate, setUserUpdate] = useState(props.user);
    // console.log("userUpdate user= ==", userUpdate)

   

  useEffect(() => {
    setLimit(props.usedLimit)
  }, [props])
  

    const onUpdateValue = (key, value) => {
        setUserUpdate({
            ...userUpdate,
            [key]: value
        })
    }

    const onTutorials = () => {
        window.open("https://medizues.com/getting-started/", '_blank')
    }

    const onFaq = () => {
        window.open("https://medizues.com/integrations/", '_blank')
    }

    const onRefer = () => {
        window.open("https://medizues.com/affiliates", '_blank')
    }

    return(
        <div className="mt-10">
            <h1 className="text-base font-bold">User Information</h1>

            <div className="w-full flex flex-wrap mt-5">
                <div className="w-full md:w-1/2 lg:w-1/2 pr-2">
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">First Name:</div>
                        <Input
                            className="w-full md:w-5/6 lg:w-5/6"
                            value={(userUpdate && userUpdate.first_name) ? userUpdate["first_name"] : ''}
                            onChange={(value, e) => {
                                onUpdateValue("first_name", value)
                            }} 
                        />
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">Last Name:</div>
                        <Input
                            className="w-full md:w-5/6 lg:w-5/6"
                            value={(userUpdate && userUpdate.last_name) ? userUpdate["last_name"] : ''}
                            onChange={(value, e) => {
                                onUpdateValue("last_name", value)
                            }} 
                        />
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">Email:</div>
                        <Input
                            disabled={true}
                            className="w-full md:w-5/6 lg:w-5/6"
                            value={(userUpdate && userUpdate.email) ? userUpdate["email"] : ''}
                            onChange={(value, e) => {
                                onUpdateValue("email", value)
                            }} 
                        />
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">Phone:</div>
                        <Input
                            className="w-full md:w-5/6 lg:w-5/6"
                            value={(userUpdate && userUpdate.phone && userUpdate.phone != undefined && userUpdate.phone != 'undefined') ? userUpdate["phone"] : ''}
                            onChange={(value, e) => {
                                onUpdateValue("phone", value)
                            }} 
                        />
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">NPN:</div>
                        <Input
                            disabled={true}
                            className="w-full md:w-5/6 lg:w-5/6"
                            value={(userUpdate && userUpdate.npn) ? userUpdate["npn"] : ''}
                            onChange={(value, e) => {
                                onUpdateValue("npn", value)
                            }} 
                        />
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">API Key:</div>
                        <div className='flex relative w-full md:w-5/6 lg:w-5/6 items-center'>
                            <Input
                                disabled={true}
                                className="w-full pr-10"
                                value={(userUpdate && userUpdate.apiToken) ? userUpdate["apiToken"] : ''}
                                onChange={(value, e) => {
                                    onUpdateValue("apiToken", value)
                                }} 
                            />
                            <div className='absolute right-2'>
                                <CopyToClipboardButton textToCopy={userUpdate.apiToken} />
                            </div>
                        </div>
                    </div>
                     <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">Daily Limit:</div>
                        <div className="w-full md:w-5/6 lg:w-5/6">
                            <a className='text-sm text-green-700 font-medium no-underline' target="_blank" href="https://www.medizues.com/enterprise/#enterprisepricing">Increase daily limit</a>
                            <Input
                                disabled={true}
                                className=""
                                value={(userUpdate && userUpdate.dailyFetchLimit) ? userUpdate["dailyFetchLimit"] : ''}
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap mt-0 w-full items-center py-3">
                        <div className="w-full md:w-1/6 lg:w-1/6">Used:</div>
                        <Input
                            disabled={true}
                            className="w-full md:w-5/6 lg:w-5/6"
                            value={limit}
                        />
                    </div>
                    <div onClick={() => props.onSave(userUpdate)} 
                        className={`mt-3 justify-center flex font-bold cursor-pointer px-8 py-2 rounded-md text-sm text-white border-2 border-solid border-green-200 bg-green-200`}>
                            Save
                    </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/2 pl-16 text-base">
                    <div className='flex flex-col gap-y-2'>
                    <div className='text-base font-bold'>User links</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://docs.google.com/spreadsheets/d/1RhmE7yH_Ro0TQAZG99CAsCmaHATs-SyxKiTuSMwVx2Y/edit?usp=sharing", '_blank')}>2024 All Plans Summary of Benefits (Nationwide)</div>
                      
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://medizues.com/2024-plan-crosswalks/", '_blank')}>2024 Plan Crosswalks</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://checkout.medizues.com/p/login/aEU01G2UieIz7Dy288", '_blank')}>Billing Portal</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://medizues.com/disenrollment-forms/", '_blank')}>Carrier Disenrollment Forms</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://zues.featureos.app/roadmap/", '_blank')}>Feature Roadmap</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://medizues.com/getting-started/", '_blank')}>Getting Started</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://medizues.com/integrations/", '_blank')}>Integrations</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://medizues.com/", '_blank')}>MediZues.com homepage</div>
                    <div className='hover:underline hover:text-green-200 hover:font-bold cursor-pointer' onClick={()=>window.open("https://medizues.com/support/", '_blank')}>Support</div>
                    </div>
                </div>
            </div>
        </div>
     
    )
}

export default UserInfo;